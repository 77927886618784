.text-underline {
	text-decoration: underline;
}

.text-strong {
	font-weight: bold;
	color: $vermelho-ed;
}

.text-link {
	color: $vermelho-ed;

	&:hover {
		color: $vermelho-ed;		
	}
}