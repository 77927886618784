.navbar-lang {
	border-bottom: 5px solid $vermelho-ed;
}

@media (min-width: 991.98px) { 
	.dropdown-menu-educacao {
		margin-top: 12px;
		margin-left: .5rem;
		border-top-left-radius: unset;
		border-top-right-radius: unset;
	}
}