main > .container {
	padding: 65px 15px 0;
}

.heading-observacao {
	font-weight: 800;
	margin: 20px 0;
	padding: 30px;
	background-image:url(../../assets/images/pattern.png);
	background-position:center left;
	background-repeat:no-repeat;
	background-size: 151px auto;
	text-shadow: 3px 5px 2px #c6c6c6;
}

.h2-observacao {
	font-weight: 800;
}

.map-educacao {
	height: 250px;
}

.square-edu {
	width: 25px;
	height: 25px;
	// background-color: red;
	display: inline-block;
}

@media (min-width: 991.98px) { 
	.map-educacao {
		height: 500px;
	}
}