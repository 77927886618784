html,body {
    height: 100%;
}

body.full-page {
	display: -ms-flexbox;
	display: flex;
	color: #fff;
	background: url(../../assets/images/banner_alt.png) no-repeat center center fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.cover-container {
    max-width: 42em;
}

.cover {
	padding: 0 1.5rem;
}
.cover .btn-lg {
	padding: .75rem 1.25rem;
	font-weight: 700;
}