.ct-series-a .ct-area, .ct-series-a .ct-slice-donut-solid, .ct-series-a .ct-slice-pie {
	fill: #008000 !important;
}

.ct-series-b .ct-area, .ct-series-b .ct-slice-donut-solid, .ct-series-b .ct-slice-pie {
	fill: #b22222 !important;
}

.ct-series-c .ct-area, .ct-series-c .ct-slice-donut-solid, .ct-series-c .ct-slice-pie {
	fill: #ff7f00 !important;
}

.chart-1 {
	background-color: #008000;
}

.chart-2 {
	background-color: #b22222;
}

.chart-3 {
	background-color: #ff7f00;
}